<template>
    <Layout>
        <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div class="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Login</h2>
                <p class="mt-2 text-center text-sm text-gray-600">
                    Loggen Sie sich ein, um Ihre Kurse zu absolvieren.
                </p>
            </div>

            <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <form @submit.prevent="form.post('/login')" class="space-y-6">
                        <div>
                            <label class="block text-sm font-medium text-gray-700">Email Adresse</label>
                            <div class="mt-1">
                                <input v-model="form.email" type="email" autocomplete="email"
                                       class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md
                                       shadow-sm placeholder-gray-400 focus:outline-none focus:ring-dbblue-500
                                       focus:border-dbblue-500 sm:text-sm" />
                                <span v-if="form.errors.email" class="mt-2 text-sm text-red-700">{{ form.errors.email }}</span>
                            </div>
                        </div>
                        <div>
                            <label class="block text-sm font-medium text-gray-700">Passwort</label>
                            <div class="mt-1">
                                <input v-model="form.password" type="password" autocomplete="current-password"
                                        class="appearance-none block w-full px-3 py-2 border border-gray-300
                                        rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-dbblue-500
                                        focus:border-dbblue-500 sm:text-sm" />
                                <span v-if="form.errors.password" class="mt-2 text-sm text-red-700">{{ form.errors.password }}</span>
                            </div>
                        </div>

                        <div class="flex items-center justify-between">
                            <div class="flex items-center">
                                <input id="remember_me" v-model="form.remember_me" type="checkbox" class="h-4 w-4 text-dbblue-600
                                    focus:ring-dbblue-500 border-gray-300 rounded" />
                                <label for="remember_me" class="ml-2 block text-sm text-gray-900">Eingeloggt bleiben</label>
                            </div>

                            <div class="text-sm">
                                <Link :href="route('passwort-vergessen.index')" class="font-medium text-dbblue-600 hover:text-dbblue-500">Passwort vergessen?</Link>
                            </div>
                        </div>
                        <div>
                            <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent
                                rounded-md shadow-sm text-sm font-medium text-white bg-dbblue-600 hover:bg-dbblue-700
                                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dbblue-500">Einloggen</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from '../components/layout/LayoutDefault.vue';
import Button from "../components/basic/Button.vue";
import {Link, useForm} from "@inertiajs/vue3";

export default {
    components: {
        Layout,
        Button,
        Link
    },
    setup() {
        const form = useForm({
            email: null,
            password: null,
            remember_me: true
        })

        return {form}
    }
}
</script>
